/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
    path: '/huayang',
    component: Layout,
    children: [{
        path: '',
        name: 'index',
        meta: {
            title: "中介超市"
        },
        component: () =>
            import ('@/views/huayang/index.vue')
    }, {
        path: '/huayang/interaction',
        name: 'interaction',
        meta: {
            title: "区企互动"
        },
        component: () =>
            import ('@/views/huayang/interaction.vue')
    }, {
        path: '/huayang/classroom',
        name: 'classroom',
        meta: {
            title: "知识大讲堂"
        },
        component: () =>
            import ('@/views/huayang/classroom.vue')
    }, {
        path: '/huayang/particulars',
        name: 'particulars',
        meta: {
            title: "知识大讲堂"
        },
        component: () =>
            import ('@/views/huayang/particulars.vue')
    }, {
        path: '/huayang/company',
        name: 'company',
        meta: {
            title: "企业详情"
        },
        component: () =>
            import ('@/views/huayang/company.vue')
    }, {
        path: '/huayang/firmlogin',
        name: 'firmlogin',
        meta: {
            title: "企业登录"
        },
        component: () =>
            import ('@/views/huayang/firmlogin.vue')
    }, {
        path: '/huayang/lianluo',
        name: 'lianluo',
        meta: {
            title: "联系我们"
        },
        component: () =>
            import ('@/views/huayang/lianluo.vue')
    }, {
        path: '/huayang/serve',
        name: 'serve',
        meta: {
            title: "企业生产要素"
        },
        component: () =>
            import ('@/views/huayang/serve.vue')
    }, ]
}

export default router