import Layout from '@/layout'

const villageRouter = {
    path: '/register',
    component: Layout,
    children: [{
        path: '/',
        name: 'register',
        meta: {
            title: "防疫排查"
        },
        component: () =>
            import ('@/views/fangyi/register.vue')
    }, {
        path: '/register/luo',
        name: 'luo',
        meta: {
            title: "返洛人员登记"
        },
        component: () =>
            import ('@/views/fangyi/luo.vue')
    }, {
        path: '/register/yimiao',
        name: 'yimiao',
        meta: {
            title: "疫苗接种排查"
        },
        component: () =>
            import ('@/views/fangyi/yimiao.vue')
    }, {
        path: '/register/newAdd',
        name: 'newAdd',
        meta: {
            title: "居民信息登记"
        },
        component: () =>
            import ('@/views/fangyi/newAdd.vue')
    }, ]
}

export default villageRouter